import React from "react";
import Select, { components } from "react-select";
import arrow_green from "../../../public/images/arrow_green.svg";

import "./SelectComponentGreen.scss";

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div
                    className={props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"}
                >
                    <img src={arrow_green} alt="arrow_green" />
                </div>
            </components.DropdownIndicator>
        )
    );
};

const styles = {
    option: (styles, state) => {
        return {
            ...styles,
            color: state.isSelected ? "black !important" : null,
            opacity: state.isSelected ? "1 !important" : null,
            fontFamily: state.isSelected ? "OpenSansRegular !important" : null
        };
    }
};

const SelectComponentGreen = ({
    openMenuOnClick,
    defaultValue,
    onSearchChange,
    searchValue,
    value,
    options,
    loading,
    change,
    placeholder,
    isClearable,
    isSearchable = false,
    isDisabled = false
}) => (
    <Select
        //    menuIsOpen
        maxMenuHeight={90}
        openMenuOnClick={openMenuOnClick}
        className="select_component_green"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={loading}
        isClearable={!isClearable}
        isSearchable={isSearchable}
        name="color"
        value={value}
        options={options}
        onChange={change}
        loadingMessage={() => "Loading filters..."}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        styles={styles}
        inputValue={searchValue}
        onInputChange={onSearchChange}
        defaultValue={defaultValue}
    />
);

export default SelectComponentGreen;
