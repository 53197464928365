var langCalculator = {
    ru: {
        stpp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё<br/>(СЃС‚Р°РЅРґР°СЂС‚РЅР°СЏ)",
        ppdp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё<br/>(РґРµРЅСЊРіРё РІ РїРµСЂРёРѕРґРµ)",
        pp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё",
        ip_title: "РњРіРЅРѕРІРµРЅРЅР°СЏ СЂР°СЃСЃСЂРѕС‡РєР°",
        amount: "РЎСѓРјРјР° РїР»Р°С‚РµР¶Р°",
        hrn_per_month: "РіСЂРЅ / РјРµСЃ",
        term: "РЎСЂРѕРє РєСЂРµРґРёС‚РѕРІР°РЅРёСЏ",
        price: "РЎС‚РѕРёРјРѕСЃС‚СЊ С‚РѕРІР°СЂР°",
        month: "РјРµСЃСЏС†РµРІ",
        payments: "РїР»Р°С‚РµР¶РµР№",
        hrn: "РіСЂРёРІРµРЅ",
        compensation: "РЎСѓРјРјР° РІРѕР·РјРµС‰РµРЅРёСЏ",
        showTariff: "РџРѕСЃРјРѕС‚СЂРµС‚СЊ С‚Р°СЂРёС„РЅСѓСЋ СЃРµС‚РєСѓ",
        printRateSheet: "Р Р°СЃРїРµС‡Р°С‚Р°С‚СЊ",
        calculator: "РљР°Р»СЊРєСѓР»СЏС‚РѕСЂ",
        real_percent_pp: "СЂРµР°Р»СЊРЅР°СЏ % СЃС‚Р°РІРєР° РћР§ - 0,24%",
        real_percent_ip: "СЂРµР°Р»СЊРЅР°СЏ % СЃС‚Р°РІРєР° РњР  - 84,04%",
        allAmount: "РѕР±С‰Р°СЏ СЃС‚РѕРёРјРѕСЃС‚СЊ РєСЂРµРґРёС‚Р° "
    },
    uk: {
        stpp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚РёРЅР°РјРё<br/>(СЃС‚Р°РЅРґР°СЂС‚РЅР°)",
        ppdp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚РёРЅР°РјРё<br/>(РіСЂРѕС€С– РІ РїРµСЂС–РѕРґС–)",
        pp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚РёРЅР°РјРё",
        ip_title: "РњРёС‚С‚С”РІР° СЂРѕР·СЃС‚СЂРѕС‡РєР°",
        amount: "РЎСѓРјР° РїР»Р°С‚РµР¶Сѓ",
        hrn_per_month: "РіСЂРЅ/РјС–СЃ",
        term: "РЎС‚СЂРѕРє РєСЂРµРґРёС‚СѓРІР°РЅРЅСЏ",
        price: "Р’Р°СЂС‚С–СЃС‚СЊ С‚РѕРІР°СЂСѓ",
        month: "РјС–СЃСЏС†С–РІ",
        payments: "РїР»Р°С‚РµР¶С–РІ",
        hrn: "Р“СЂРёРІРµРЅСЊ",
        compensation: "РЎСѓРјР° РІС–РґС€РєРѕРґСѓРІР°РЅРЅСЏ",
        showTariff: "РџРµСЂРµРіР»СЏРЅСѓС‚Рё С‚Р°СЂРёС„Рё",
        printRateSheet: "Р РѕР·РґСЂСѓРєСѓРІР°С‚Рё",
        calculator: "РљР°Р»СЊРєСѓР»СЏС‚РѕСЂ",
        real_percent_pp: "СЂРµР°Р»СЊРЅР° % СЃС‚Р°РІРєР° РћР§ - 0,24%",
        real_percent_ip: "СЂРµР°Р»СЊРЅР° % СЃС‚Р°РІРєР° РњР  - 84,04%",
        allAmount: "Р·Р°РіР°Р»СЊРЅР° РІР°СЂС‚С–СЃС‚СЊ РєСЂРµРґРёС‚Сѓ "
    },
    ka: {
        stpp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё<br/>(СЃС‚Р°РЅРґР°СЂС‚РЅР°СЏ)",
        ppdp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё<br/>(РґРµРЅСЊРіРё РІ РїРµСЂРёРѕРґРµ)",
        pp_title: "РћРїР»Р°С‚Р° С‡Р°СЃС‚СЏРјРё",
        ip_title: "РњРіРЅРѕРІРµРЅРЅР°СЏ СЂР°СЃСЃСЂРѕС‡РєР°",
        amount: "РЎСѓРјРјР° РїР»Р°С‚РµР¶Р°",
        hrn_per_month: "РіСЂРЅ / РјРµСЃ",
        term: "РЎСЂРѕРє РєСЂРµРґРёС‚РѕРІР°РЅРёСЏ",
        price: "РЎС‚РѕРёРјРѕСЃС‚СЊ С‚РѕРІР°СЂР°",
        month: "РјРµСЃСЏС†РµРІ",
        payments: "РїР»Р°С‚РµР¶РµР№",
        hrn: "РіСЂРёРІРµРЅ",
        compensation: "РЎСѓРјРјР° РІРѕР·РјРµС‰РµРЅРёСЏ",
        showTariff: "РџРѕСЃРјРѕС‚СЂРµС‚СЊ С‚Р°СЂРёС„РЅСѓСЋ СЃРµС‚РєСѓ",
        printRateSheet: "Р Р°СЃРїРµС‡Р°С‚Р°С‚СЊ",
        calculator: "РљР°Р»СЊРєСѓР»СЏС‚РѕСЂ",
        real_percent_pp: "СЂРµР°Р»СЊРЅР°СЏ % СЃС‚Р°РІРєР° РћР§ - 0,24%",
        real_percent_ip: "СЂРµР°Р»СЊРЅР°СЏ % СЃС‚Р°РІРєР° РњР  - 84,04%",
        allAmount: "РѕР±С‰Р°СЏ СЃС‚РѕРёРјРѕСЃС‚СЊ РєСЂРµРґРёС‚Р° "
    },
    en: {
        stpp_title: "Payment by parts<br/>(standard)",
        ppdp_title: "Payment by parts<br/>(money in period)",
        pp_title: "В«Payment by partsВ»",
        ip_title: "Instant installments",
        amount: "Payment amount",
        hrn_per_month: "UAH / month",
        term: "Credit terms",
        price: "Cost of goods",
        month: "months",
        payments: "payments",
        hrn: "UAH",
        compensation: "Reimbursement amount",
        showTariff: "VIEW TARIFF SCHEDULE",
        printRateSheet: "Print",
        calculator: "Calculator",
        real_percent_pp: "real interest rate PP - 0,24%",
        real_percent_ip: "real interest rate II - 84,04%",
        allAmount: "total loan costs "
    }
};

var curLangCalculator = "ru";

export var PP_CALCULATOR = (function() {
    var my = {};
    var commissions = {
        ipCommission: 2.9,
        acqCommission: 0.02,
        ppCommission: 0.015
    };

    function privParseInt(num) {
        return parseInt(num, 10);
    }

    function getValByTerm(term) {
        var commissions = {
            1: 0.015,
            2: 0.025,
            3: 0.045,
            4: 0.07,
            5: 0.09,
            6: 0.115,
            7: 0.135,
            8: 0.155,
            9: 0.165,
            10: 0.17,
            11: 0.175,
            12: 0.19,
            13: 0.205,
            14: 0.22,
            15: 0.235,
            16: 0.245,
            17: 0.26,
            18: 0.27,
            19: 0.285,
            20: 0.295,
            21: 0.31,
            22: 0.32,
            23: 0.33,
            24: 0.345
        };
        return commissions[term];
    }

    my.calculatePhys = function(paymentsCount, price) {
        if (isNaN(paymentsCount) || isNaN(price)) return;
        paymentsCount = privParseInt(paymentsCount) + 1;
        price = privParseInt(price);
        var ip = price / paymentsCount + price * (commissions.ipCommission / 100);
        var pp = price / paymentsCount + price * 0.0001;
        var ppAll = price + price * 0.0001 * (paymentsCount - 1);
        var ipAll = price + price * 0.029 * (paymentsCount - 1);
        return {
            payCount: paymentsCount,
            ipValue: ip.toFixed(2),
            ppValue: pp.toFixed(2),
            ppAll: langCalculator[curLangCalculator].allAmount + " " + ppAll.toFixed(2),
            ipAll: langCalculator[curLangCalculator].allAmount + " " + ipAll.toFixed(2)
        };
    };
    my.calculateJur = function(paymentsCount, price) {
        if (isNaN(paymentsCount) || isNaN(price)) return;
        paymentsCount = privParseInt(paymentsCount) + 1;
        price = privParseInt(price);
        var tabVal = getValByTerm(paymentsCount - 1);
        var stpp = price * (1 - (tabVal + commissions.acqCommission));
        var pp = 0;
        var ppValHint = "0.00";
        var singlePayment = price / paymentsCount;
        var ppFirst = singlePayment - price * (commissions.acqCommission + commissions.ppCommission);
        var ppSecond = singlePayment;
        var ppOther = (paymentsCount - 1) * ppSecond;
        pp = ppFirst + ppOther;
        ppValHint = ppFirst.toFixed(2) + " + " + (paymentsCount - 1) + "*" + ppSecond.toFixed(2);
        var ip = price * (1 - commissions.acqCommission);
        return {
            payCount: paymentsCount,
            stPpValue: stpp.toFixed(2),
            ipValue: ip.toFixed(2),
            ppValue: pp.toFixed(2),
            ppValueHint: ppValHint
        };
    };
    return my;
})();
